var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-tabs',{attrs:{"color":"#002441"}},[_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"8"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}}),_c('v-btn',{staticClass:"me-10 mt-1 ",attrs:{"color":"red","width":"100px"},on:{"click":_vm.addButton}},[_vm._v("Syc Payment")])],1)],1),_c('v-tab-item',[_c('RecentOrders')],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-row',[_c('v-col',[[_c('v-card',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card-title',{staticClass:"pb-10 "},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.exportTableToCSV('all_orders.csv')}}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"black"}},[_vm._v("mdi-download ")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.print}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"black"}},[_vm._v("mdi-printer ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"8"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"From Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateto1),callback:function ($$v) {_vm.dateto1=$$v},expression:"dateto1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateto1),callback:function ($$v) {_vm.dateto1=$$v},expression:"dateto1"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"To Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2=false}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-btn',{staticClass:"mr-2 font-weight-bold",staticStyle:{"background":"gray"},attrs:{"text":""},on:{"click":_vm.seereport}},[_vm._v(" Submit ")])],1)],1),_c('div',{staticClass:"mt-4",attrs:{"id":"dataapp"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.orderHeaders,"items":_vm.orders,"loading":_vm.loading,"server-items-length":_vm.totalItems1,"hide-default-footer":"","loading-text":"Please wait...","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('v-spacer'),_c('div',{staticClass:"pr-4 pb-4 pt-2 d-flex "},[_c('div',{staticClass:"search g"},[_c('v-text-field',{attrs:{"label":"Search by order id, date","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search_key),callback:function ($$v) {_vm.search_key=$$v},expression:"search_key"}})],1),_c('div',{staticClass:"icon-search pr-4 pb-5 pt-2"},[_c('v-btn',{staticStyle:{"background":"red"},attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.searchHandle}},[_vm._v("mdi-magnify")])],1)],1)])],1)]},proxy:true},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v("Details")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.admin_status == 'Confirmed')?_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v("Invoice")]):_vm._e()]}},{key:"item.motherinvoice",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.viewMotherInvoice(item)}}},[_c('v-icon',{staticClass:"pr-5",staticStyle:{"margin-left":"0 !important"},attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" "+_vm._s(item.reference_id)+" ")],1)]}},{key:"item.admin_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.admin_status),"dark":""}},[_vm._v(" "+_vm._s(item.admin_status)+" ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"col-4"},[_vm._v(" Total "+_vm._s(_vm.totalItems1)+" records ")]),_c('div',{staticClass:"col-4"},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Items per page","items":_vm.pageSizes1},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.perPage1),callback:function ($$v) {_vm.perPage1=$$v},expression:"perPage1"}})],1),_c('div',{staticClass:"col-4"},[_c('v-pagination',{attrs:{"length":_vm.totalPages1,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.currentPage1),callback:function ($$v) {_vm.currentPage1=$$v},expression:"currentPage1"}})],1)])],1)]],2)],1)],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }